import i18n from '@/plugins/i18n'

export const required = [
  v => !!v || i18n.t('validations.required')
]

export const email = [
  v => !!v || i18n.t('validations.required'),
  v => /.+@.+\..+/.test(v) || i18n.t('validations.email')
]

export const emailNoRequieed = [
  v => /.+@.+\..+/.test(v) || i18n.t('validations.email')
]
