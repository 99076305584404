<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col>
          <v-checkbox
            v-model="form.isPublic"
            :label="checkboxLabel"
            color="primary"
          ></v-checkbox>
          <v-textarea
            class="required-label"
            outlined
            name="input-7-4"
            :label="checkboxLabel"
            v-model="form.message"
            :rules="required"
          ></v-textarea>
          <v-row justify="end">
            <v-btn
              class="mr-3 mb-4"
              right
              dark
              small
              rounded
              color="primary"
              :title="btnLabel"
              @click="addNote()"
            >
              <v-icon
                small
                class="px-1"
              >
                {{ btnIcon }}
              </v-icon>
              {{ btnLabel }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col
        cols="12"
        v-for="(item, index) of notes"
        :key="index"
        class="d-flex"
        v-bind:class="[ item.idusuario === $store.getters.getLoggedUser.id ? 'justify-start' : 'justify-end' ]"
      >
        <ba-card
          class="note-card"
          :hover="false"
          :item="item"
          :headers="headers"
          :styleType="'note'"
        />
      </v-col>
    </v-row>
    <div v-if="notes.length > 0">
      <ba-pagination
        :params="params"
        @reload="getNotes"
      />
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import BaCard from './Card.vue'
import BaPagination from './Pagination.vue'
import { required } from './../utils/validations'

export default {
  name: 'ba-notes',
  components: {
    'ba-card': BaCard,
    'ba-pagination': BaPagination
  },
  props: {
    checkboxLabel: {
      type: String,
      default: i18n.t('contact.components.notes.public')
    },
    btnLabel: {
      type: String,
      default: i18n.t('contact.components.notes.add')
    },
    btnIcon: {
      type: String,
      default: 'mdi-send'
    },
    idObject: Number, // example case Id, contact Id
    typeObject: String // options 'casos' 'contactos'
  },
  data: () => {
    return {
      form: {
        isPublic: false,
        message: ''
      },
      headers: [
        { label: '', value: 'nombre', icon: '', visible: false },
        { label: '', value: 'apellido', icon: '', visible: false },
        { label: '', value: 'isPublic', icon: '', visible: false },
        { label: '', value: 'message', icon: '', visible: true },
        { label: '', value: 'date', icon: '', visible: true }
      ],
      snackText: '',
      notes: [],
      required: required,
      params: {
        idobjeto: 0,
        tipoobjeto: 0,
        pagina: 1,
        cantidad: 3,
        cantPages: 1
      }
    }
  },
  watch: {
    value: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  methods: {
    getNotes () {
      this.params.idobjeto = this.idObject
      this.params.tipoobjeto = this.typeObject
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('note/getAllNotes', this.params)
        .then((response) => {
          this.notes = response.data.map(item => {
            return this.getUserName(item)
          })
          this.params.cantPages = response.pagination.cantPages
          this.$store.dispatch('loader/done')
        })
        .catch((err) => {
          this.$store.dispatch('loader/done')
          this.$store.dispatch('snackbar/alert', { type: 'error', msg: `${this.$t('contact.components.snackbar.requestError')} : ${err}` })
        })
    },
    addNote () {
      if (this.$refs.form.validate()) {
        const note = {
          privado: this.form.isPublic ? 1 : 0,
          texto: this.form.message,
          idobjeto: this.idObject,
          tipoobjeto: this.typeObject
        }
        this.$store.dispatch('loader/pending')
        this.$store.dispatch('note/addNote', note).then(message => {
          this.notes.push(this.getUserName(message))
          this.$store.dispatch('snackbar/alert', { type: 'success', msg: this.$t('contact.components.snackbar.addMsg') })
          this.$store.dispatch('loader/done')
          this.$refs.form.resetValidation()
          this.reset()
        })
          .catch((err) => {
            this.$store.dispatch('loader/done')
            this.$store.dispatch('snackbar/alert', { type: 'error', msg: `${this.$t('contact.components.snackbar.notesError')} : ${err}` })
          })
      } else {
        this.$store.dispatch('snackbar/alert', { type: 'error', msg: this.$t('validations.snackbar.requiredMessage') })
      }
    },
    getUserName (item) {
      const user = item.idusuariodesc ? item.idusuariodesc : 'Sistema Beaware'
      return {
        ...item,
        nombre: user.split(' ')[0] || '',
        apellido: user.split(' ')[1] || ''
      }
    },
    reset () {
      this.form.isPublic = false
      this.form.message = ''
      this.getNotes()
    }
  },
  mounted () {
    console.log(this.$store.getters.getLoggedUser.id)
    this.getNotes()
  }
}
</script>

<style scoped>
.note-card {
  width: 50%;
}
@media only screen and (max-width: 600px) {
  .note-card {
    width: 100%;
  }
}
</style>
