<template>
  <v-container class="ba-container-contact mt-3">
    <slot></slot>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>
@media (min-width: 1904px) {
  .container.ba-container-contact {
    max-width: 900px;
  }
}

@media (max-width: 959px) {
  .container.ba-container-contact {
    border-radius: none;
    box-shadow: none;
  }
}

@media (min-width: 960px) {
  .container.ba-container-contact {
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
</style>
