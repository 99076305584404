<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <v-expansion-panel
      class="mt-2"
      :key="1"
    >
      <v-expansion-panel-header>
        <v-icon
          class="ba-expansion ba-icon"
          color="primary"
        >{{ icon }}</v-icon>{{ title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'ba-expansion',
  props: {
    title: String,
    icon: {
      type: String,
      default: 'mdi-information'
    }
  },
  data () {
    return {
      panel: []
    }
  },
  methods: {
    open () {
      this.panel.push(0)
    },
    close () {
      this.panel = []
    },
    isOpen () {
      return this.panel.length > 0
    }
  }
}
</script>

<style scoped>
.ba-expansion.ba-icon {
  display: inherit;
  max-width: 40px;
}
</style>
